import { Divider } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div<{ sliderArrowTop: number }>`
  width: 100%;
  background: ${(props) => props.theme.color.primarySubtle};
  ${(props) => props.theme.typo.familyGoogle.regular};
  color: ${(props) => props.theme.color.onSurface};
  padding-bottom: 48px;

  .customer-title {
    ${props => props.theme.typo.familyGoogle.bold}
    font-size: 20px;
    text-align: center;
    padding: 40px 35px 0 35px;
  }

  .customer-desc {
    font-size: 12px;
    text-align: center;
    padding: 0 35px 19px 35px;
  }

  .ant-divider {
    width: 80px;
    min-width: 80px;
    margin: 20px auto;
    border-top: 2px solid #ff5722;
  }

  .slider-wrapper {
    .slick-list {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }

    .slick-next::before,
    .slick-prev::before {
      display: none !important;
    }

    .slider-arrows {
      top: ${(props) => props.sliderArrowTop}px !important;
    }
  }

  @media screen and (min-width: 768px) {
    .customer-title {
      font-size: 24px;
    }

    .customer-desc {
      font-size: 14px;
    }
  }
`;

export const StyledCard = styled.div<{ mobileHeight: number; desktopHeight: number }>`
  width: 96% !important;
  height: ${(props) => props.mobileHeight}px;
  background: ${(props) => props.theme.color.background};
  padding: 28px 24px;
  box-shadow: 0px 4px 27.97px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;

  .card-profile {
    text-align: center;
    margin-bottom: 10px;

    &__avatar {
      position: relative;
      width: 78px;
      height: 78px;
      margin: 0px auto 10px;

      img {
        border-radius: 100%;
        margin-bottom: 10px;
        width: 100%;
        height: 100%;
      }
    }

    &__name {
      font-size: 16px;
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }
  }

  .card-profile-desc {
    text-align: center;

    .ant-rate {
      width: 100%;
      font-size: 19px;
      height: 19px;
      line-height: 19px;
      margin-bottom: 22px;
    }

    &__review {
      height: 172px;
      overflow-y: auto;

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
        background-color: ${(props) => props.theme.color.webkitScrollBar};
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: ${(props) => props.theme.color.webkitScrollBarThumb};
        border-color: ${(props) => props.theme.color.webkitScrollBarThumb};
      }
    }

    &__source {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        font-size: 15px;
      }

      .facebook {
        color: ${(props) => props.theme.color.fbColor};
      }

      .google {
        color: ${(props) => props.theme.color.pureRed};
      }

      &__timeline {
        ${(props) => props.theme.typo.familyGoogle.regular};
        font-size: 10px;
        color: ${(props) => props.theme.color.webkitScrollBarThumb};
        margin-left: 7px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    height: ${(props) => props.desktopHeight}px;
    flex-direction: row;
    padding: 32px 22px 21px 43px;
    max-width: 545px !important;

    .card-profile {
      width: 15%;
      margin-right: 38px;
    }

    .card-profile-desc {
      width: 85%;
      text-align: left;

      &__review {
        height: 109px;
      }

      &__source {
        justify-content: flex-start;
      }
    }
  }
`;

export const StyledDesc = styled.div`
  padding: 0 32px;
  text-align: center;
  font-size: 12px;
  color: ${(props) => props.theme.color.darkShapeBlue};

  a {
    color: ${(props) => props.theme.color.lightOrange};
    ${(props) => props.theme.typo.familyGoogle.bold};
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    padding: 12px 0;
    font-size: 14px;
  }
`;

const StyledArrow = styled.div`
  display: block;
  width: 40px !important;
  height: 40px !important;
  text-align: center;

  svg {
    background: ${(props) => props.theme.color.background};
    color: ${(props) => props.theme.color.primaryBase};
    font-size: 40px;
    border-radius: 40px;
  }
`;

export const StyledNextArrow = styled(StyledArrow)`
  right: 55px !important;
  z-index: 2;
`;

export const StyledPrevArrow = styled(StyledArrow)`
  left: 55px !important;
  z-index: 2;
`;

export const StyledDivider = styled(Divider)`
  border-top-width: 5px !important;
`;
