import React, { FC, useState } from 'react';
import { Rate } from 'antd';
import { FacebookFilled, GoogleSquareFilled, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import Slider, { Settings, CustomArrowProps } from 'react-slick';
import { Trans, useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/blur.css';

import { TitleTypography } from '@design-system/components';
import {
  StyledCard,
  StyledDesc,
  StyledDivider,
  StyledNextArrow,
  StyledPrevArrow,
  StyledWrapper,
} from '@source/components/CustomerReview/CustomerReview.styles';
import { CustomerReviewProps, ICustomerReview } from '@source/components/CustomerReview/CustomerReview.types';
import { getIsAdjacentToActiveIndex } from '@design-system/components/BrowsingCard/utils';
import CustomTextWithFontFamily from '../CustomTextWithFontFamily';

const PrevArrow: FC = (props: CustomArrowProps): JSX.Element => {
  const { className, style, onClick } = props;
  return (
    <StyledPrevArrow className={`${className} slider-arrows`} style={{ ...style }} onClick={onClick}>
      <LeftCircleFilled />
    </StyledPrevArrow>
  );
};

const NextArrow: FC = (props: CustomArrowProps): JSX.Element => {
  const { className, style, onClick } = props;
  return (
    <StyledNextArrow className={`${className} slider-arrows`} style={{ ...style }} onClick={onClick}>
      <RightCircleFilled />
    </StyledNextArrow>
  );
};

const CustomerReview: FC<CustomerReviewProps> = ({ content }) => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(0);

  const settings: Settings = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          className: 'center',
          variableWidth: false,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      setActiveIndex(newIndex);
    },
  };

  const { header, customerReviews, footer } = content;

  // To align the slider arrows top position with the footer desc
  // Based on the lack of the card-profile-desc__source
  const [customerReviewItem] = customerReviews;
  const hasDataSource = customerReviewItem.type && customerReviewItem.time;
  const sliderArrowTop = hasDataSource ? 354 : 306;

  // To adjust mobile card height
  // Based on the lack of the card-profile-desc__source
  const mobileCardHeight = hasDataSource ? 456 : 395;
  const desktopCardHeight = hasDataSource ? 251 : 203;

  return (
    <StyledWrapper sliderArrowTop={sliderArrowTop}>
      <TitleTypography className="customer-title" level={2}>
        <Trans
          defaults={header?.title}
          components={{ span: <span />, strong: <strong />, specificFont: <span style={{ fontFamily: 'Poppins' }} /> }}
        />
      </TitleTypography>
      <StyledDivider />
      <div className="customer-desc">{t(header?.description)}</div>

      <div className="slider-wrapper">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...settings}>
          {customerReviews.map((item: ICustomerReview, index) => (
            <StyledCard key={item?.name} mobileHeight={mobileCardHeight} desktopHeight={desktopCardHeight}>
              <div className="card-profile">
                <div className="card-profile__avatar">
                  <LazyLoadImage
                    effect="blur"
                    alt="customer"
                    src={item.avatar}
                    visibleByDefault={getIsAdjacentToActiveIndex(index, activeIndex, customerReviews.length)}
                  />
                </div>
                <div className="card-profile__name">{item.name}</div>
              </div>
              <div className="card-profile-desc">
                <Rate disabled defaultValue={item.star} />
                <div className="card-profile-desc__review">{item.review}</div>
                {item.type && item.time && (
                  <div className="card-profile-desc__source">
                    <span>
                      {item.type === 'facebook' && <FacebookFilled className="facebook" />}
                      {item.type === 'google' && <GoogleSquareFilled className="google" />}
                    </span>
                    <span className="card-profile-desc__source__timeline">{item.time}</span>
                  </div>
                )}
              </div>
            </StyledCard>
          ))}
        </Slider>
      </div>

      <StyledDesc>
        <CustomTextWithFontFamily text={t(footer?.preText)} />
        <a href={footer?.anchor?.link} target="_blank" rel="noreferrer">
          {t(footer?.anchor?.text)}
        </a>
        <CustomTextWithFontFamily text={t(footer?.postText)} />
      </StyledDesc>
    </StyledWrapper>
  );
};

export default CustomerReview;
